body {
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  font-family: 'Roboto', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-paper {
  width: 60% !important;
  background-image: -webkit-linear-gradient(30deg, #013a6b 50%, #004e95 50%);
}

.MuiTypography-root {
  color: black;
}

@media (max-width: 780px) {
  .MuiDrawer-paper {
    width: 100% !important;
  }
}

.react-multi-carousel-track {
  align-items: center;
}

html {
  margin: 0 auto !important;
  float: none !important;
  background-color: rgba(244, 244, 244);
  min-width: 280px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #100d0d !important;
}

@media (max-width: 959px) {
  body {
    max-width: 95%;
    margin: 0 auto;
    padding: 0;
  }
}
